<template>
	<div class="store__container">
		<div class="white_page_wrapper">
			<StanLogoSvg />

			<div class="content-container">
				<ConstructionSvg />
				<div class="content-message">This Stan Store is currently under construction.</div>
			</div>
			<div class="login-container">
				<div class="login-message">
					<div>Is this your store?</div>
					<div>Log in to reactivate your account.</div>
				</div>
				<div class="button button-primary" @click="login()">Login</div>
				<div class="button button-secondary" @click="signUp()">Sign Up</div>
			</div>
		</div>
	</div>
</template>

<script>
	import StanLogoSvg from '~/assets/images/stan-logo.svg'
	import ConstructionSvg from '~/assets/images/construction.svg'

	export default {
		components: {
			StanLogoSvg,
			ConstructionSvg,
		},
		methods: {
			login() {
				window.location = `${process.env.NUXT_ENV_ADMIN_URL}`
			},
			signUp() {
				window.location = `${process.env.NUXT_ENV_ADMIN_URL}register`
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import '~/stan-vue-shared/assets/styles/themes/index.scss';

	.store__container {
		background: #ebebff;
		.white_page_wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 28px;
			padding-top: 40px;
			font-family: 'Plus Jakarta Sans', sans-serif;
			color: #131f60;
			font-weight: 400;

			.content-container {
				display: flex;
				flex-direction: column;
				gap: 38px;
				justify-content: center;
				align-items: center;
				width: 100%;

				.content-message {
					text-align: center;
					max-width: 80%;
					font-size: 20px;
					font-weight: 600;
				}
			}

			.login-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				width: 100%;

				.login-message {
					margin-bottom: 28px;
				}

				.button {
					display: flex;
					border: 2px solid #6355ff;
					border-radius: 8px;
					width: 100%;
					height: 48px;
					justify-content: center;
					align-items: center;
					font-weight: 700;
					cursor: pointer;

					&.button-primary {
						color: #ffffff;
						background: #6355ff;
						margin-bottom: 16px;
						max-width: 720px;
						transition: 0.2s ease;
					}
					&.button-secondary {
						color: #6355ff;
						max-width: 720px;
						transition: 0.2s ease;
					}
				}
			}
		}
	}
</style>
